<template>
    <div class="bg bg-circle fade-in" id="img-container">
        <img src="@/assets/bottomRightCircle/bottomRightCircle.webp" alt="">
    </div>
</template>

<script>
export default {
    name: "BottomRightCircleComp",
}
</script>

<style scoped>
#img-container {
    background-image: url("@/assets/bottomRightCircle/bottomRightCircle.webp");
}
</style>
