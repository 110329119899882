<template>
<div class="container">
    <LogoMenuBarComp/>
    Our Friends
</div>
</template>

<script>
import LogoMenuBarComp from "@/components/LogoMenuBar.vue";

export default {
    name: "FriendsPage",
    components: {LogoMenuBarComp}
}
</script>

<style scoped>

</style>
