<template>
    <div @click="action" id="container">
        <svg class="icon" v-bind:width="size" v-bind:height="size" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M512.0768 988.70784c167.33696 0 302.94528-135.60832 302.94528-302.9504V338.03264c0-167.33184-135.60832-302.94528-302.94528-302.94528s-302.9504 135.61344-302.9504 302.94528v347.73504c0 167.33184 135.61344 302.94016 302.9504 302.94016zM269.63456 337.89952c0-133.79072 108.51328-242.304 242.44224-242.304 133.92384 0 242.304 108.5184 242.304 242.304v348.0064c0 133.78048-108.5184 242.29888-242.304 242.29888-133.92896 0-242.44224-108.5184-242.44224-242.29888v-348.0064z" fill="rgba(255, 255, 255, .5)"></path>
            <path d="M560.08704 766.48448c0 22.31808-21.34016 40.42752-48.01024 40.42752-26.53696 0-48.01024-17.9712-48.01024-40.42752v-121.14944c0-22.3232 21.34016-40.43264 48.01024-40.43264 26.53184 0 48.01024 17.9712 48.01024 40.43264v121.14944z" fill="rgba(255, 255, 255, .5)"></path>
        </svg>
    </div>
</template>

<script>
export default {
    name: "DownwardButtonComp",
    props: {
        size: {
            type: Number,
            default: 24,
        },
        action: {
            type: Function,
            required: true,
        }
    }
}
</script>
