<template>
    <div class="bg fade-in fade-in-delay-1" id="img-container">
        <img src="@/assets/cloud/cloud.webp" alt="">
    </div>
</template>

<script>
export default {
    name: "CloudComp"
}
</script>

<style scoped>
#img-container {
    background-image: url("@/assets/cloud/cloud.webp");
}
</style>
