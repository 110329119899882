<script setup>

</script>

<template>
    <svg v-bind:width="size" v-bind:height="size" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" id="logo">
        <path d="M143.857 43.1517C136.809 30.9494 119.191 30.9495 112.143 43.1517L108.477 49.5H117C119.209 49.5 121 51.2909 121 53.5V53.502C120.999 55.7102 119.208 57.5 117 57.5H103.856L69.2027 117.5H76.5C78.9853 117.5 81 119.515 81 122C81 124.485 78.9853 126.5 76.5 126.5H64.0047L30.4804 184.545C23.4329 196.747 32.2422 212 46.3372 212H125.684V181.183C125.684 176.122 121.28 172.818 117.221 169.773L117.03 169.63C107.197 162.25 104.949 159.296 103.197 149C101.197 137.25 107.934 116.5 107.934 116.5C107.934 116.5 109.852 124.5 112.801 127.542C115.357 130.18 118.724 132.194 122.434 133.75C121.137 130.333 120.603 126.847 120.623 123.414C120.697 110.971 128.047 99.225 132.684 94C133.371 101.763 135.684 120.75 142.684 129C143.951 130.493 145.103 131.788 146.14 132.954C150.836 138.234 153.184 140.874 153.184 147.25C153.184 156.934 147.678 165.332 139.626 169.484C135.125 171.805 131.184 175.884 131.184 180.947V212H209.663C223.758 212 232.567 196.747 225.52 184.545L222.586 179.466C222.394 179.488 222.198 179.5 222 179.5H186C183.239 179.5 181 177.261 181 174.5C181 171.739 183.239 169.5 186 169.5H216.83L171.781 91.5H159C156.791 91.5 155 89.7091 155 87.5C155 85.2909 156.791 83.5 159 83.5H167.16L143.857 43.1517Z" fill="white" fill-opacity="0.8"/>
    </svg>
</template>

<script>
export default {
    name: "LogoComp",
    props: {
        size: {
            type: Number,
            default: 128
        }
    }
}
</script>

<style scoped>
#logo {
    filter: brightness(150%);
}
</style>
